<template>
  <TTView>
    <VRow>
      <VCol>
        <h3>Snackbar</h3>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <TTBtn @click="toggleSnack">
          Add snack
        </TTBtn>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <TTSnackbar
          v-model="snack.value"
          :type="snack.type"
        >
          {{ snack.msg }}
          <template #action="{attrs}">
            <TTBtn
              color="pink"
              text
              @click="snack.value = false"
            >
              {{ attrs }}
            </TTBtn>
          </template>
        </TTSnackbar>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'Snackbar',
  data() {
    return {
      snack: {
        type: 'info', value: false, msg: 'I am snackbar',
      },
    };
  },
  methods: {
    toggleSnack() {
      this.snack.value = true;
    },
  },
};
</script>

<style scoped>

</style>
